import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { connect } from 'react-redux'

import AuthLayout from 'layouts/auth'
import Seo from 'components/seo'
import Icon from 'components/icon'
import ProgressBar from 'components/progressBar/index'
import { Card, Divider, Grid } from '@mui/material'

import { links } from '/static/data/signup.static'

import { getLoginAsUrl, sendDataLayerEvent } from 'utils'

import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

const OauthGoogleCallbackPage = ({ setMessageDialogOpen, setErrorMessageI18n }) => {
  const { t, i18n } = useTranslation()

  const hubspotToken = Cookies.get('hubspotutk')
  const apiUrl = process.env.GATSBY_MLY_API_URL

  const [currentFlow, setCurrentFlow] = useState(0)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isValidating, setIsValidating] = useState(true)
  const [redirectData, setRedirectData] = useState({
    tokenID: '',
    name: '',
    email: '',
    hd: '',
    from: ''
  })

  const handleCallback = () => {
    const queryString = window.location.hash
    const urlParams = new URLSearchParams(queryString)
    const tokenID = urlParams.get('id_token')
    const tokenDecodeData = jwtDecode(tokenID)
    const { name, email } = tokenDecodeData
    const hd = urlParams.get('hd')
    const { from } = JSON.parse(urlParams.get('#state'))
    setRedirectData({
      tokenID,
      name,
      email,
      hd,
      from
    })
    if (currentFlow === 0) {
      handleLogin(tokenID)
    }
  }

  const handleLogin = async (tokenID) => {
    try {
      const payload = {
        googleJwtToken: tokenID,
        action: 'googleLogin',
        hubspotToken
      }
      const response = await fetch(`${apiUrl}/auth/v2/auth/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      const json = await response.json()

      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }

      const { customerId, organizationId, jwtToken } = json.data

      window.location.href = getLoginAsUrl(customerId, organizationId, jwtToken)
    } catch (error) {
      // if got error code '1460', currentFlow = 1, signup with google
      if (error.response && error?.response?.code === 1460) {
        setIsValidating(false)
        setCurrentFlow(1)
      } else {
        const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
          ? `errorMessages.${error?.response?.code}`
          : 'errorMessages.signUpWithGoogle'
        setErrorMessageI18n(errorMessage)
        setMessageDialogOpen(true)
        setTimeout(() => {
          navigate('/sign-up')
        }, 2000)
      }
    }
  }

  const handleSignupWithGoogle = async () => {
    setIsActionLoading(true)
    try {
      const payload = {
        googleJwtToken: redirectData.tokenID,
        action: 'googleRegister',
        hubspotToken
      }

      const response = await fetch(`${apiUrl}/auth/v2/auth/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      const json = await response.json()

      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }

      const { customerId, organizationId, jwtToken } = json.data

      const eventPayload = {
        event: 'sign_up',
        method: 'google',
        user_id: organizationId,
        email: redirectData.email
      }

      sendDataLayerEvent(eventPayload)

      window.location.href = getLoginAsUrl(customerId, organizationId, jwtToken)
    } catch (error) {
      const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
        ? `errorMessages.${error?.response?.code}`
        : 'errorMessages.signUpWithGoogle'
      setErrorMessageI18n(errorMessage)
      setMessageDialogOpen(true)
    } finally {
      setIsActionLoading(false)
    }
  }

  useLayoutEffect(() => {
    const arr = window.location.hash.replace('#', '').split('&')
    const newArr = arr.map((it) => {
      const oldArr = it.split('=')
      return oldArr[0]
    })
    const checkArr = ['access_token', 'state', 'id_token']
    const validateArr = checkArr.filter((item) => {
      return newArr.includes(item)
    })
    if (validateArr.length < 3) {
      throw new Error('Failed to handle google callback: Missing key')
    } else {
      handleCallback()
    }
  }, [])

  const CustomTransComponents = ({ links = [] }) => ({
    href: (
      <React.Fragment>
        {links?.map((link, linkIndex) => (
          <a
            key={linkIndex}
            id={link.clickID}
            href={link.href}
            className="underline cursor-pointer text-blue-200"
            target="_blank"
            rel="noreferrer"
          >
            {t(link.text)}
          </a>
        ))}
      </React.Fragment>
    )
  })

  return (
    <AuthLayout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'Sign up with Google | Mlytics',
            metaDescription: 'Please sign up using your Google account on Mlytics.',
            metaUrl: 'https://www.mlytics.com/oauth/google-callback/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />

        <section
          className="container py-10 flex items-center justify-center"
          style={{
            minHeight: 'calc(100vh - 148px)'
          }}
        >
          <Card
            className="relative rounded-lg p-8 pt-16"
            sx={{
              margin: '0 auto',
              boxShadow: '0px 8px 36px 0px rgba(0,0,0, 0.1)',
              overflow: 'visible',
              minHeight: isValidating ? '150px' : '400px',
              width: '100%',
              maxWidth: '464px'
            }}
          >
            {isValidating ? (
              <div>
                <div
                  style={{
                    position: 'absolute',
                    top: '-60%',
                    left: '50%',
                    transform: 'translate(-50%, 60%)'
                  }}
                >
                  <div
                    className="p-2 bg-white rounded-full flex items-center justify-center"
                    style={{ width: '86px', height: '86px', boxShadow: '0px 8px 36px 0px rgba(0,0,0, 0.1)' }}
                  >
                    <Icon className="text-blue-200" name="person" fontSize="43px" iconStyle="" />
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <Grid xs item className="text-center">
                    <ProgressBar className="mb-6" />
                    <p>{t('oAuthGoogleCallbackPage.validatingText')}</p>
                  </Grid>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translate(-50%, -43px)'
                  }}
                >
                  <div
                    className="p-2 bg-white rounded-full flex items-center justify-center"
                    style={{ width: '86px', height: '86px', boxShadow: '0px 8px 36px 0px rgba(0,0,0, 0.1)' }}
                  >
                    <Icon className="text-blue-200" name="person" fontSize="43px" iconStyle="" />
                  </div>
                </div>
                <h1 className="mb-6 text-blue-300 text-center text-3.5xl">{t('signUpPage.title')}</h1>
                <div className="flex flex-col">
                  <div className="p-3 flex flex-row bg-blue-50 bg-opacity-10 gap-2 items-center">
                    <Icon className="text-blue-50" name="account_circle_outlined" fontSize="40px" />
                    <div>
                      <div className="text-gray-800">{redirectData.name}</div>
                      <div className="text-gray-400">{redirectData.email}</div>
                    </div>
                  </div>
                  <Divider
                    sx={{
                      marginBottom: '24px',
                      padding: '12px 0px'
                    }}
                  />
                  <p className="pb-3 text-center text-gray-400 text-xs">
                    <Trans
                      i18nKey="signUpPage.termsAndPrivacyText"
                      components={CustomTransComponents({ links: links.slice(0, 2) })}
                    />
                  </p>

                  <button
                    className={`mb-6 btn btn-blue ${isActionLoading ? 'disabled' : ''}`}
                    disabled={isActionLoading}
                    onClick={handleSignupWithGoogle}
                  >
                    {isActionLoading ? (
                      <svg
                        className="animate-spin h-4 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-r-gray-50 border-t-gray-50"
                        style={{
                          width: '24px',
                          height: '24px'
                        }}
                        viewBox="0 0 32 32"
                      ></svg>
                    ) : (
                      <span>{t('button.signUp.0')}</span>
                    )}
                  </button>
                  <p className="mb-6 text-gray-700 text-center">
                    <Trans i18nKey="signUpPage.alreadyHaveAccountText">
                      <a
                        className="text-blue-200 underline underline-offset-2 font-bold"
                        href="https://portal.mlytics.com/login"
                      ></a>
                    </Trans>
                  </p>
                </div>
              </div>
            )}
          </Card>
        </section>
      </React.Fragment>
    </AuthLayout>
  )
}

OauthGoogleCallbackPage.propTypes = {
  setMessageDialogOpen: PropTypes.func,
  setErrorMessageI18n: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  setMessageDialogOpen: (isOpen) => dispatch({ type: 'SET_MESSAGE_DIALOG_OPEN', payload: isOpen }),
  setErrorMessageI18n: (i18nKey) => dispatch({ type: 'SET_ERROR_MESSAGE_I18N_KEY', payload: i18nKey })
})

export default connect(null, mapDispatchToProps)(OauthGoogleCallbackPage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
